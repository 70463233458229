import React from "react";
import { Typography } from "@mui/material";
import Layout from "../components/Layout";

const Thanks = () => {
  return (
    <Layout useHashLinks>
      <div
        style={{ height: "100%" }}
        className="flex flex-col items-center gap-2 p-14"
      >
        <Typography variant="body1" className="text-lg">
          Thanks! You have successfully subscribed to my newsletter, and a
          welcome email is on its way to your inbox.
        </Typography>
        <Typography variant="body1" className="text-lg">
          Please be sure to add{" "}
          <a href="mailto:lisa@lisamaybennett.com">lisa@lisamaybennett.com</a>{" "}
          to your contacts so that my emails don't get diverted to your spam
          folder or promotions tab.
        </Typography>
      </div>
    </Layout>
  );
};

export default Thanks;
